<template>
  <div>
    <div style="display: flex; justify-content: space-between;margin-bottom:15px;">
      <v-btn
        v-if="!content.styleSettings"
        class="object__submit btn btn_primary"
        color="primary"
        outlined
        small
        solo
        @click="openPointList"
      >
        <v-icon>mdi-chevron-left</v-icon>
        Back to point list
      </v-btn>
      <v-btn
        v-if="content.styleSettings"
        class="object__submit btn btn_primary"
        color="primary"
        outlined
        small
        solo
        @click="openStyles"
      >
        <v-icon>mdi-chevron-left</v-icon>
        Back to point detail
      </v-btn>
    </div>
    <div v-if="!content.styleSettings" style="padding-bottom:15px;">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        "
      >
        <div style="flex: 1; margin-bottom: 15px">
          <div class="label" style="margin-bottom: 10px">
            Choose your main image
          </div>
          <vue-dropzone
            id="createDropzone"
            :options="dropzoneOptions"
            :destroyDropzone="false"
            ref="createDropzone"
            @vdropzone-mounted="editPointDropzoneMountMain"
            @vdropzone-sending="customEvent"
            @vdropzone-success="handleSuccessTrailPointMainImage"
            @vdropzone-removed-file="handleRemoveTrailPointMainImage"
          >
          </vue-dropzone>
        </div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        "
      >
        <div style="flex: 1; margin-bottom: 15px">
          <div class="label" style="margin-bottom: 10px">
            Choose your details image
          </div>
          <vue-dropzone
            id="createDropzoneDetails"
            :options="dropzoneOptions"
            :destroyDropzone="false"
            ref="createDropzoneDetails"
            @vdropzone-mounted="editPointDropzoneMount"
            @vdropzone-sending="customEvent"
            @vdropzone-success="handleSuccessTrailPointDetailImage"
            @vdropzone-removed-file="handleRemoveTrailPointDetailImage"
          >
          </vue-dropzone>
        </div>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="flex: 1; padding-right: 15px">
          <div class="label" style="margin-bottom: 15px">Latitude</div>
          <v-text-field
            outlined
            dense
            v-model.number="trailPoint.coordinates.lat"
            type="number"
            placeholder="Your point latitude"
          >
          </v-text-field>
        </div>
        <div style="flex: 1">
          <div class="label" style="margin-bottom: 15px">Longtitude</div>
          <v-text-field
            outlined
            dense
            type="number"
            v-model.number="trailPoint.coordinates.lon"
            placeholder="Your point longtitude"
          >
          </v-text-field>
        </div>
        <div style="flex: 1; padding-left: 15px">
          <div class="label" style="margin-bottom: 15px">Distance</div>
          <v-text-field
            outlined
            dense
            type="number"
            v-model="trailPoint.link"
            placeholder="Your point distance"
          >
          </v-text-field>
        </div>
      </div>

      <div class="margin" style="flex: 1">
        <label class="label">Change icon</label>
        <v-autocomplete
          dense
          flat
          outlined
          solo
          class="input input_dense"
          :items="marker_icons"
          hide-details
          v-model="trailPoint.icon"
        >
          <template v-slot:selection="data">
            <span
              :class="`map-icon map-icon-${data.item.split(' ').join('-')}`"
              style="font-size: 20px; margin-right: 10px; color: #000"
            ></span>
            {{ data.item }}
          </template>
          <template v-slot:item="data">
            <span
              :class="`map-icon map-icon-${data.item.split(' ').join('-')}`"
              style="font-size: 20px; margin-right: 10px; color: #000"
            ></span>
            {{ data.item }}
          </template>
        </v-autocomplete>
      </div>
      <v-tabs v-model="activeLang" style="margin-bottom: 30px">
        <v-tab
          v-for="(lang, i) in landing.selected_langs"
          :key="lang + i + 'select'"
          @change="changeLang(lang)"
        >
          <img
            :src="landing.advanced.langs.find((x) => x.value === lang).flag"
            alt=""
            style="width: 20px; height: 15px; margin-right: 5px"
          />
          {{ lang }}
        </v-tab>
        <v-tab-item
          style="padding-top: 15px"
          v-for="(lang, i) in landing.selected_langs"
          :key="lang + i + 'selected'"
        >
          <div style="display: flex; justify-content: space-between">
            <div style="padding-right: 15px; flex: 1">
              <div class="label" style="margin-bottom: 15px">Name</div>
              <v-text-field
                outlined
                dense
                v-model="trailPoint.name[lang]"
                placeholder="Your trail name"
              ></v-text-field>
            </div>
            <div style="padding-left: 15px; flex: 1">
              <div class="label" style="margin-bottom: 15px">Video Link</div>
              <v-text-field
                outlined
                dense
                v-model="trailPoint.video_link[lang]"
                placeholder="Your trail video link"
              ></v-text-field>
            </div>
          </div>
          <div style="margin-bottom: 15px">
            <div class="label" style="margin-bottom: 15px">
              Small description
            </div>
            <editor
              v-if="showMce"
              v-model="trailPoint.small_description[lang]"
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="optionsSmall"
            />
          </div>
          <div>
            <div class="label" style="margin-bottom: 15px">
              Detail description
            </div>
            <editor
              v-if="showMce"
              v-model="trailPoint.description[lang]"
              api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
              :init="options"
            />
          </div>
        </v-tab-item>
      </v-tabs>
      <v-btn @click="editConfirm" outlined small color="primary">
        Save edit
      </v-btn>
    </div>
    <div v-if="styles">
      <type-trail-points-detail-styles :content="content" />
    </div>
    <PopupSuccess ref="PopupSuccess" title="Success" content="You trail edited succesfully!" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import ImagesService from "@/services/images.service.js";
import vueDropzone from "vue2-dropzone";
import TypeTrailPointsDetailStyles from "./typeTrailPointsDetailStyles";
import PopupSuccess from "../../../Popup/PopupSuccess";

export default {
  name: "typeTrailPointDetail",
  data() {
    return {
      showMce: false,
      styles: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      optionsSmall: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 250,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      marker_icons: [
        "abseiling",
        "accounting",
        "airport",
        "amusement park",
        "aquarium",
        "archery",
        "art gallery",
        "assistive listening system",
        "atm",
        "audio description",
        "bakery",
        "bank",
        "bar",
        "baseball",
        "beauty salon",
        "bicycle store",
        "bicycling",
        "boat ramp",
        "boat tour",
        "boating",
        "book store",
        "bowling alley",
        "braille",
        "bus station",
        "cafe",
        "campground",
        "canoe",
        "car dealer",
        "car rental",
        "car repair",
        "car wash",
        "casino",
        "cemetery",
        "chairlift",
        "church",
        "circle",
        "city hall",
        "climbing",
        "closed captioning",
        "clothing store",
        "compass",
        "convenience store",
        "courthouse",
        "cross country skiing",
        "crosshairs",
        "dentist",
        "department store",
        "diving",
        "doctor",
        "electrician",
        "electronics store",
        "embassy",
        "expand",
        "female",
        "finance",
        "fire station",
        "fish cleaning",
        "fishing pier",
        "fishing",
        "florist",
        "food",
        "fullscreen",
        "funeral home",
        "furniture store",
        "gas station",
        "general contractor",
        "golf",
        "grocery or supermarket",
        "gym",
        "hair care",
        "hang gliding",
        "hardware store",
        "health",
        "hindu temple",
        "horse riding",
        "hospital",
        "ice fishing",
        "ice skating",
        "inline skating",
        "insurance agency.txt",
        "jet skiing",
        "jewelry store",
        "kayaking",
        "laundry",
        "lawyer",
        "library",
        "liquor store",
        "local government",
        "location arrow",
        "locksmith",
        "lodging",
        "low vision access",
        "male",
        "map pin",
        "marina",
        "mosque",
        "motobike trail",
        "movie rental",
        "movie theater",
        "moving company",
        "museum",
        "natural feature",
        "night club",
        "open captioning",
        "painter",
        "park",
        "parking",
        "pet store",
        "pharmacy",
        "physiotherapist",
        "place of worship",
        "playground",
        "plumber",
        "point of interest",
        "police",
        "political",
        "post box",
        "post office",
        "postal code prefix",
        "postal code",
        "rafting",
        "real estate agency.txt",
        "restaurant",
        "roofing contractor",
        "route pin",
        "route",
        "rv park",
        "sailing",
        "school",
        "scuba diving",
        "search",
        "shield",
        "shopping mall",
        "sign language",
        "skateboarding",
        "ski jumping",
        "skiing",
        "sledding",
        "snow shoeing",
        "snow",
        "snowboarding",
        "snowmobile",
        "spa",
        "square pin",
        "square rounded",
        "square",
        "stadium",
        "storage",
        "store",
        "subway station",
        "surfing",
        "swimming",
        "synagogue",
        "taxi stand",
        "tennis",
        "toilet",
        "trail walking",
        "train station",
        "transit station",
        "travel agency.txt",
        "unisex",
        "university",
        "veterinary care",
        "viewing",
        "volume control telephone",
        "walking",
        "waterskiing",
        "whale watching",
        "wheelchair",
        "wind surfing",
        "zoo",
        "zoom in alt",
        "zoom in",
        "zoom out alt",
        "zoom out",
      ],
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
    };
  },
  computed: {
    ...mapState("trails", ["trailPoint"]),
    ...mapState(["landing"]),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  methods: {
    ...mapActions("trails", ["editTrailPoint"]),
    ...mapMutations("trails", ["setPage", "setTrailPoint"]),
    async editConfirm() {
      await this.editTrailPoint(this.trailPoint);
      this.$refs.PopupSuccess.open();
    },
    openStyles() {
      this.content.styleSettings = false
    },
    openPointList() {
      this.setPage("points");
      this.setTrailPoint(null);
    },
    editPointDropzoneMountMain() {
      if (this.trailPoint.main_img) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.trailPoint,
        };
        this.$refs.createDropzone.dropzone.removeAllFiles();
        this.$refs.createDropzone.manuallyAddFile(
          mockFile,
          this.trailPoint.main_img
        );
        this.$refs.createDropzone.dropzone.emit(
          "thumbnail",
          mockFile,
          this.trailPoint.main_img
        );
        this.$refs.createDropzone.dropzone.emit("complete", mockFile);
      }
    },
    editPointDropzoneMount() {
      let mockFile = {
        accepted: true,
        cropped: true,
        kind: "image",
        ...this.trailPoint,
      };
      let elements =
        this.$refs.createDropzoneDetails.$el.getElementsByClassName(
          "dz-preview"
        );
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      for (let i = 0; i < this.trailPoint.images.length; i++) {
        mockFile.removeItemIndex = i;
        this.$refs.createDropzoneDetails.manuallyAddFile(
          mockFile,
          this.trailPoint.images[i]
        );
        this.$refs.createDropzoneDetails.dropzone.emit(
          "thumbnail",
          mockFile,
          this.trailPoint.images[i]
        );
        this.$refs.createDropzoneDetails.dropzone.emit("complete", mockFile);
      }
    },
    handleSuccessTrailPointDetailImage(file) {
      this.trailPoint.images.push(file.xhr.response);
    },
    async handleRemoveTrailPointDetailImage(file) {
      if (this.trailPoint.images.includes(file.xhr.response)) {
        this.trailPoint.images.splice(
          this.trailPoint.images.indexOf(file.xhr.response),
          1
        );
        await ImagesService.deleteImage(file.xhr.response);
      }
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    async handleRemoveTrailPointMainImage(file) {
      if (file.name === this.trailPoint.main_img) {
        await ImagesService.deleteImage(file.name);
        this.trailPoint.main_img = "";
      } else if (file?.xhr?.response === this.trailPoint.main_img) {
        await ImagesService.deleteImage(file.name);
      }
    },
    handleSuccessTrailPointMainImage(file) {
      this.trailPoint.main_img = file.xhr.response;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.showMce = true;
      }, 3000);
    });
  },
  beforeDestroy() {
    this.showMce = false;
  },
  components: {
    PopupSuccess,
    TypeTrailPointsDetailStyles,
    vueDropzone,
  },
  props: ["content"],
};
</script>

<style lang="scss" scoped></style>
