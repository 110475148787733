<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <v-btn color="primary" outlined @click="$emit('openTrails')" small
        ><v-icon>mdi-chevron-left</v-icon>
        <span
          >Back to trails list</span
        ></v-btn
      >
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="$emit('openTrails')"
            >
              Content settings
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div>
      <div>
        <div>
          <label class="label" style="font-weight: bold; font-size: 20px"
          >General settings</label
          >
          <div style="padding:0 15px;">
            <div
                class="margin"
                style="display: flex; width: 100%; margin-bottom: 15px"
            >
              <div style="display:flex;align-items:center;flex:1;margin-right:15px;">

                <label class="label" style="display:block;width:200px;margin-bottom:0;margin-right:8px;">Latitude</label>
                <v-text-field
                    dense
                    hide-details
                    type="number"
                    outlined
                    v-model="trail.map.coordinates.lat"
                ></v-text-field>
              </div>
              <div style="display:flex;align-items:center;flex:1">
                <label class="label" style="display:block;width:200px;margin-bottom:0;margin-right:8px;">Longitude</label>
                <v-text-field
                    dense
                    hide-details
                    type="number"
                    outlined
                    v-model="trail.map.coordinates.lon"
                ></v-text-field>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom:15px;">
              <label class="label" style="display:block;min-width:200px;margin-bottom:0;">Zoom range <b>{{ trail.map.zoom }} eq</b></label>
              <div class="slider" style="width:100%;">
                <v-slider
                    :max="18"
                    :min="0"
                    v-model="trail.map.zoom"
                    hide-details
                ></v-slider>
              </div>
            </div>
            <div style="display:flex;align-items:center;margin-bottom: 15px">
              <div style="display:flex;align-items:center;flex:1;margin-right:15px;">
                <label class="label" style="width:200px;margin-bottom:0;display:block;margin-right:8px;">Driving mode</label>
                <v-select
                    dense
                    hide-details
                    outlined
                    :items="trail_mode_list"
                    v-model="trail.map.travel_mode"
                >
                </v-select>
              </div>
              <div style="flex:1;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="control">
          <label class="label" style="font-weight: bold; font-size: 20px"
            >Polyline settings</label
          >
        </div>
        <div style="padding:0 15px;">
          <div style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
            >Weight <b>{{ content.styles.map.strokeWeight }} px</b></label
            >
            <div class="slider" style="width:100%;">
              <v-slider
                  hide-details
                  :min="1"
                  :max="50"
                  v-model="content.styles.map.strokeWeight"
              ></v-slider>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 30px">
            <label class="label" style="margin-bottom: 0;width:200px; margin-right: 8px">
              Polyline color
            </label>
            <div class="control">
              <v-menu
                  top
                  nudge-left="16"
                  nudge-bottom="98"
                  :close-on-content-click="false"
                  v-model="show_text_color"
              >
                <template v-slot:activator="{ on }">
                  <div
                      class="custom__menu--color"
                      :style="`background-color:${content.styles.map.strokeColor}`"
                      v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="content.styles.map.strokeColor" flat />
                  </v-card-text>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
        <div>
            <div class="control">
                <label class="label" style="font-weight: bold; font-size: 20px"
                >Bottom image settings</label
                >
              <div style="padding:0 15px;">

                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
                  >Width <b>{{ content.styles.map.img_width }}</b> px</label
                  >
                  <div class="slider" style="width:100%;">
                    <v-slider
                        hide-details
                        :min="50"
                        :max="300"
                        v-model="content.styles.map.img_width"
                    ></v-slider>
                  </div>
              </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
                  >Height <b>{{ content.styles.map.img_height }} px</b></label
                  >
                  <div class="slider" style="width:100%;">
                    <v-slider
                        hide-details
                        :min="50"
                        :max="300"
                        v-model="content.styles.map.img_height"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;margin-bottom: 15px;align-items: center;">
                  <label class="label" style="width: 200px;margin-bottom:0;margin-right:8px;">Enable custom direction</label>
                  <v-switch color="#6ab64a" hide-details v-model="enable_custom_path" @change="changeCustom">
                  </v-switch>
                </div>
                <div v-if="enable_custom_path">
                  <v-textarea v-model="path_route" outlined>

                  </v-textarea>
                </div>
              </div>
            </div>
        </div>
    </div>
      <v-btn outlined small @click="editConfirm" color="primary" style="margin-bottom: 15px;">
          Edit
      </v-btn>
  </div>
</template>

<script>
import { mapState,mapActions } from "vuex";
export default {
  name: "typeTrailMap",
  data() {
    return {
      show_text_color: false,
        path_route:"",
        enable_custom_path:false,
      trail_mode_list: [
        "DRIVING",
        "WALKING",
        "BICYCLING",
        "TRANSIT",
        "TWO_WHEELER",
      ],
    };
  },
    props:['content'],
  computed: {
    ...mapState("trails", ["trail"]),
  },
    methods:{
      ...mapActions('trails',['editTrail']),
        changeCustom(){
          if(this.enable_custom_path){
              this.trail.enable_custom_path = 0;
          }else{
              this.trail.enable_custom_path = 1;
          }
        },
        editConfirm(){
          if(this.trail.enable_custom_path){
              this.trail.route = this.path_route.slice(5,-6).split(' ').reduce((acc,item) => {
                 const lat = item.split(',')[0];
                  const lng = item.split(',')[1].replace(';','');
                  const obj = {
                      lat:+lat,
                      lng:+lng
                  };
                  acc.push(obj);
                  return acc
              },[]);
          }
          this.editTrail(this.trail);
        },
    },
    mounted(){
      if(this.trail.enable_custom_path){
          this.enable_custom_path = true;
      }
        if(this.trail.route.length>0){
            this.path_route = '[map]';
            this.path_route += this.trail.route.reduce((acc,item) => {
                acc+=item.lat+","+item.lng+" ";
                return acc
            },"");
            this.path_route += "[/map]";
        }
    }
};
</script>

<style lang="scss" scoped></style>
