<template>
  <div style="height: 100%;">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <v-btn color="primary" outlined @click="$emit('openTrails')" small
        ><v-icon>mdi-chevron-left</v-icon>
        <span
          >Back to trails list</span
        ></v-btn
      >
    </div>
    <div
      v-if="!content.styleSettings"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <label class="label" style="margin-bottom: 0">Trail points list</label>
      <v-btn
        class="object__submit btn btn_primary"
        style="float: right"
        color="primary"
        outlined
        small
        solo
        @click="createPointShow"
      >
        Create point
      </v-btn>
    </div>
    <div
      v-if="!content.styleSettings">
      <v-data-table
        class="table"
        :headers="headers"
        :items="trail.route_points"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id" @click="openDetail(item)">
              <td class="d-block d-sm-table-cell" style="text-align: center">{{ item.id }}</td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.name[landing.current_lang] }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center; cursor: pointer">
                {{ item.coordinates.lat }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center; cursor: pointer">
                {{ item.coordinates.lon }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ formatDate(item.created_at) }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-chip
                  class="ma-2"
                  :color="item.active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click.stop="changeStatus(item)"
                >
                  {{ item.active ? "Active" : "Inactive" }}
                </v-chip>
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark  outlined small v-bind="attrs" v-on="on">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item style="cursor: pointer" @click="openDetail(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title style="cursor: pointer; color: red" @click="deletePointShow(item.id)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
          <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                colspan="7"
                style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have trail points yet, it's time to create them
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <div v-if="content.styleSettings">
       <type-trail-points-styles :content="content" />
      </div>

    <!--Create new trail dialog-->
    <v-dialog :fullscreen="$store.state.isMobile"
      v-model="dialogCreatePoint"
      @click:outside="closeDialogCreatePoint"
      max-width="710"
      :retain-focus="false"
      eager
    >
      <v-card>
        <v-card-title
          ><span>Create trail point</span>
          <v-icon
            style="position: absolute; top: 10px; right: 10px"
            @click="closeDialogCreatePoint"
            >mdi-close</v-icon
          ></v-card-title
        >
        <v-card-text>
          <div style="padding: 30px">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div style="flex: 1; margin-bottom: 15px">
                <div class="label" style="margin-bottom: 10px">
                  Choose your main image
                </div>
                <vue-dropzone
                  id="createDropzone"
                  :options="dropzoneOptions"
                  :destroyDropzone="false"
                  ref="createDropzone"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessTrailPointMainImage"
                  @vdropzone-removed-file="handleRemoveTrailPointMainImage"
                >
                </vue-dropzone>
              </div>
              <div style="flex: 1; margin-bottom: 15px;margin-left:15px;">
                <div class="label" style="margin-bottom: 10px">
                  Choose your details image
                </div>
                <vue-dropzone
                  id="createDropzoneDetails"
                  :options="dropzoneOptions"
                  :destroyDropzone="false"
                  ref="createDropzoneDetails"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessTrailPointDetailImage"
                  @vdropzone-removed-file="handleRemoveTrailPointDetailImage"
                >
                </vue-dropzone>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div style="flex: 1; padding-right: 15px">
                <div class="label" style="margin-bottom: 15px">Latitude</div>
                <v-text-field
                  outlined
                  dense
                  v-model.number="createdTrailPoint.coordinates.lat"
                  type="number"
                  placeholder="Your point latitude"
                >
                </v-text-field>
              </div>
              <div style="flex: 1">
                <div class="label" style="margin-bottom: 15px">Longtitude</div>
                <v-text-field
                  outlined
                  dense
                  type="number"
                  v-model.number="createdTrailPoint.coordinates.lon"
                  placeholder="Your point longtitude"
                >
                </v-text-field>
              </div>
              <div style="flex: 1; padding-left: 15px">
                <div class="label" style="margin-bottom: 15px">Distance</div>
                <v-text-field
                  outlined
                  type="number"
                  dense
                  v-model="createdTrailPoint.link"
                  placeholder="Your point distance"
                >
                </v-text-field>
              </div>
            </div>

            <div class="margin" style="flex: 1">
              <label class="label">Change icon</label>
              <v-autocomplete
                dense
                flat
                outlined
                solo
                class="input input_dense"
                :items="marker_icons"
                hide-details
                v-model="createdTrailPoint.icon"
              >
                <template v-slot:selection="data">
                  <span
                    :class="`map-icon map-icon-${data.item
                      .split(' ')
                      .join('-')}`"
                    style="font-size: 20px; margin-right: 10px; color: #000"
                  ></span>
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  <span
                    :class="`map-icon map-icon-${data.item
                      .split(' ')
                      .join('-')}`"
                    style="font-size: 20px; margin-right: 10px; color: #000"
                  ></span>
                  {{ data.item }}
                </template>
              </v-autocomplete>
            </div>

            <v-tabs v-model="activeLang" style="margin-bottom: 30px">
              <v-tab
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'select'"
                @change="changeLang(lang)"
              >
                <img
                  :src="
                    landing.advanced.langs.find((x) => x.value === lang).flag
                  "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ lang }}
              </v-tab>
              <v-tab-item
                style="padding-top: 15px"
                v-for="(lang, i) in landing.selected_langs"
                :key="lang + i + 'selected'"
              >
              <div style="display:flex;justify-content:space-between;">
                <div style="padding-right:15px;flex:1;">
                  <div class="label" style="margin-bottom: 15px">Name</div>
                  <v-text-field
                    outlined
                    dense
                    v-model="createdTrailPoint.name[lang]"
                    placeholder="Your trail name"
                  ></v-text-field>
                </div>
                <div style="padding-left:15px;flex:1;">
                  <div class="label" style="margin-bottom: 15px">Video Link</div>
                  <v-text-field
                    outlined
                    dense
                    v-model="createdTrailPoint.video_link[lang]"
                    placeholder="Your trail video link"
                  ></v-text-field>
                </div>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Small description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="createdTrailPoint.small_description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="optionsSmall"
                  />
                </div>
                <div>
                  <div class="label" style="margin-bottom: 15px">
                    Detail description
                  </div>
                  <editor
                    v-if="showMce"
                    v-model="createdTrailPoint.description[lang]"
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                  />
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            small
            outlined
            style="background: #007aff; margin-bottom: 10px; max-width: 360px"
            color="white"
            @click="createNewTrailPoint"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new trail dialog-->
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage" />
    <PopupDelete ref="PopupDelete" title="Are your sure about delete point?" @confirm="deleteTrailPointConfirm"/>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ImagesService from "@/services/images.service.js";
import moment from "moment";
import vueDropzone from "vue2-dropzone";
import PopupSuccess from "@/components/Popup/PopupSuccess"
import PopupDelete from "@/components/Popup/PopupDelete"
import TypeTrailPointsStyles from "./typeTrailPointsStyles";
export default {
  name: "typeTrailPoints",
    props:['content'],
  data() {
    return {

        optionsSmall: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: "jpg,svg,webp,gif,png",
            images_upload_handler: async (blobInfo, success, failure) => {
                let formData = new FormData();
                let _file = new File([blobInfo.blob()], blobInfo.filename(), {
                    lastModified: new Date().getTime(),
                    type: blobInfo.blob().type,
                });
                formData.append(
                    "lending_settings_id",
                    this.$store.state.landing.settings_id
                );
                formData.append("image", _file);
                formData.append("file", _file);
                const { data } = await this.$axios.post(
                    `${process.env.VUE_APP_API_URL}upload-image`,
                    formData
                );
                success(data);
            },
            plugins:
                "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
            mobile: {
                images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
                images_file_types: "jpg,svg,webp,gif,png",
                images_upload_handler: async (blobInfo, success, failure) => {
                    let formData = new FormData();
                    let _file = new File([blobInfo.blob()], blobInfo.filename(), {
                        lastModified: new Date().getTime(),
                        type: blobInfo.blob().type,
                    });
                    formData.append(
                        "lending_settings_id",
                        this.$store.state.landing.settings_id
                    );
                    formData.append("image", _file);
                    formData.append("file", _file);
                    const { data } = await this.$axios.post(
                        `${process.env.VUE_APP_API_URL}upload-image`,
                        formData
                    );
                    success(data);
                },
                plugins:
                    "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
            },
            menu: {
                tc: {
                    title: "Comments",
                    items: "addcomment showcomments deleteallconversations",
                },
            },
            menubar: "file edit view insert format tools table tc help",
            toolbar:
                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
            autosave_ask_before_unload: true,min_height:200,
            autosave_interval: "30s",
            autosave_prefix: "{path}{query}-{id}-",
            autosave_restore_when_empty: false,
            autosave_retention: "2m",
            image_advtab: true,
            link_list: [
                { title: "My page 1", value: "https://www.tiny.cloud" },
                { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_list: [
                { title: "My page 1", value: "https://www.tiny.cloud" },
                { title: "My page 2", value: "http://www.moxiecode.com" },
            ],
            image_class_list: [
                { title: "None", value: "" },
                { title: "Some class", value: "class-name" },
            ],
            importcss_append: true,
            templates: [
                {
                    title: "New Table",
                    description: "creates a new table",
                    content:
                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
                },
                {
                    title: "Starting my story",
                    description: "A cure for writers block",
                    content: "Once upon a time...",
                },
                {
                    title: "New list with dates",
                    description: "New List with dates",
                    content:
                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                },
            ],
            template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
            template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
            height: 250,
            image_caption: true,
            quickbars_selection_toolbar:
                "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
            noneditable_noneditable_class: "mceNonEditable",
            toolbar_mode: "sliding",
            contextmenu: "link image imagetools table configurepermanentpen",
            skin: "oxide",
            setup: function (editor) {
                editor.ui.registry.addButton("custom_button", {
                    text: "Add Button",
                    onAction: function () {
                        // Open a Dialog
                        editor.windowManager.open({
                            title: "Add custom button",
                            body: {
                                type: "panel",
                                items: [
                                    {
                                        type: "input",
                                        name: "button_label",
                                        label: "Button label",
                                        flex: true,
                                    },
                                    {
                                        type: "input",
                                        name: "button_href",
                                        label: "Button href",
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_target",
                                        label: "Target",
                                        items: [
                                            { text: "None", value: "" },
                                            { text: "New window", value: "_blank" },
                                            { text: "Self", value: "_self" },
                                            { text: "Parent", value: "_parent" },
                                        ],
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_rel",
                                        label: "Rel",
                                        items: [
                                            { text: "No value", value: "" },
                                            { text: "Alternate", value: "alternate" },
                                            { text: "Help", value: "help" },
                                            { text: "Manifest", value: "manifest" },
                                            { text: "No follow", value: "nofollow" },
                                            { text: "No opener", value: "noopener" },
                                            { text: "No referrer", value: "noreferrer" },
                                            { text: "Opener", value: "opener" },
                                        ],
                                        flex: true,
                                    },
                                    {
                                        type: "selectbox",
                                        name: "button_style",
                                        label: "Style",
                                        items: [
                                            { text: "Success", value: "success" },
                                            { text: "Info", value: "info" },
                                            { text: "Warning", value: "warning" },
                                            { text: "Error", value: "error" },
                                        ],
                                        flex: true,
                                    },
                                ],
                            },
                            onSubmit: function (api) {
                                var html =
                                    '<a href="' +
                                    api.getData().button_href +
                                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                                    api.getData().button_style +
                                    '--text" rel="' +
                                    api.getData().button_rel +
                                    '" target="' +
                                    api.getData().button_target +
                                    '">' +
                                    api.getData().button_label +
                                    "</a>";

                                // insert markup
                                editor.insertContent(html);

                                // close the dialog
                                api.close();
                            },
                            buttons: [
                                {
                                    text: "Close",
                                    type: "cancel",
                                    onclick: "close",
                                },
                                {
                                    text: "Insert",
                                    type: "submit",
                                    primary: true,
                                    enabled: false,
                                },
                            ],
                        });
                    },
                });
            },
            content_css: ["/custom_tinymce.css"],
        },
      showMce: false,
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      styles: false,
      mapData: {
        zoom: 5,
        coordinates: {
          lat: 10,
          lon: 11,
        },
      },
      marker_icons: [
        "abseiling",
        "accounting",
        "airport",
        "amusement park",
        "aquarium",
        "archery",
        "art gallery",
        "assistive listening system",
        "atm",
        "audio description",
        "bakery",
        "bank",
        "bar",
        "baseball",
        "beauty salon",
        "bicycle store",
        "bicycling",
        "boat ramp",
        "boat tour",
        "boating",
        "book store",
        "bowling alley",
        "braille",
        "bus station",
        "cafe",
        "campground",
        "canoe",
        "car dealer",
        "car rental",
        "car repair",
        "car wash",
        "casino",
        "cemetery",
        "chairlift",
        "church",
        "circle",
        "city hall",
        "climbing",
        "closed captioning",
        "clothing store",
        "compass",
        "convenience store",
        "courthouse",
        "cross country skiing",
        "crosshairs",
        "dentist",
        "department store",
        "diving",
        "doctor",
        "electrician",
        "electronics store",
        "embassy",
        "expand",
        "female",
        "finance",
        "fire station",
        "fish cleaning",
        "fishing pier",
        "fishing",
        "florist",
        "food",
        "fullscreen",
        "funeral home",
        "furniture store",
        "gas station",
        "general contractor",
        "golf",
        "grocery or supermarket",
        "gym",
        "hair care",
        "hang gliding",
        "hardware store",
        "health",
        "hindu temple",
        "horse riding",
        "hospital",
        "ice fishing",
        "ice skating",
        "inline skating",
        "insurance agency.txt",
        "jet skiing",
        "jewelry store",
        "kayaking",
        "laundry",
        "lawyer",
        "library",
        "liquor store",
        "local government",
        "location arrow",
        "locksmith",
        "lodging",
        "low vision access",
        "male",
        "map pin",
        "marina",
        "mosque",
        "motobike trail",
        "movie rental",
        "movie theater",
        "moving company",
        "museum",
        "natural feature",
        "night club",
        "open captioning",
        "painter",
        "park",
        "parking",
        "pet store",
        "pharmacy",
        "physiotherapist",
        "place of worship",
        "playground",
        "plumber",
        "point of interest",
        "police",
        "political",
        "post box",
        "post office",
        "postal code prefix",
        "postal code",
        "rafting",
        "real estate agency.txt",
        "restaurant",
        "roofing contractor",
        "route pin",
        "route",
        "rv park",
        "sailing",
        "school",
        "scuba diving",
        "search",
        "shield",
        "shopping mall",
        "sign language",
        "skateboarding",
        "ski jumping",
        "skiing",
        "sledding",
        "snow shoeing",
        "snow",
        "snowboarding",
        "snowmobile",
        "spa",
        "square pin",
        "square rounded",
        "square",
        "stadium",
        "storage",
        "store",
        "subway station",
        "surfing",
        "swimming",
        "synagogue",
        "taxi stand",
        "tennis",
        "toilet",
        "trail walking",
        "train station",
        "transit station",
        "travel agency.txt",
        "unisex",
        "university",
        "veterinary care",
        "viewing",
        "volume control telephone",
        "walking",
        "waterskiing",
        "whale watching",
        "wheelchair",
        "wind surfing",
        "zoo",
        "zoom in alt",
        "zoom in",
        "zoom out alt",
        "zoom out",
      ],
      defaultTrailPoint: {
        routes_id:null,
        name: {
          en: "",
        },
        description: {
          en: "",
        },
        small_description: {
          en: "",
        },
        video_link:{
          en:""
        },
        main_img: "",
        link: "",
        icon: "postal code",
        images: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
        active:1,
      },
      createdTrailPoint: {
        routes_id:null,
        active:1,
        name: {
          en: "",
        },
        description: {
          en: "",
        },
        small_description: {
          en: "",
        },
        video_link:{
          en:""
        },
        main_img: "",
        link: "",
        icon: "postal code",
        images: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
      },
      editedTrailPoint: {
        active:1,
        name: {
          en: "",
        },
        description: {
          en: "",
        },
        small_description: {
          en: "",
        },
        video_link:{
          en:""
        },
        main_img: "",
        link: "",
        icon: "postal code",
        images: [],
        coordinates: {
          lat: 45.81,
          lon: 15.98,
        },
        routes_id:null,
      },
      headers: [
        { text: "ID", value: "id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Latitude",
          value: "lat",
          align: "center",
          sortable: true,
        },
        {
          text: "Longtitude",
          value: "lon",
          align: "center",
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: "Status",
          value: "active",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      dialogCreatePoint: false,
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
            "lending_settings_id",
            this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}upload-image`,
            formData
          );
          success(data);
        },
        plugins:
          "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",
        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
          "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify |  numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
              '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
              '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",
        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                    '<a href="' +
                    api.getData().button_href +
                    '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                    api.getData().button_style +
                    '--text" rel="' +
                    api.getData().button_rel +
                    '" target="' +
                    api.getData().button_target +
                    '">' +
                    api.getData().button_label +
                    "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      successMessage:""
    };
  },
  methods: {
    ...mapActions("trails", ["editTrailPoint","createTrailPoint","deleteTrailPoint"]),
    ...mapMutations("trails",['setPage','setTrailPoint']),
    openDetail(item){
      this.setTrailPoint(item);
      this.setPage('detail');
    },
    handleSuccessTrailPointDetailImage(file){
        this.createdTrailPoint.images.push(file.xhr.response);
    },
    async handleRemoveTrailPointDetailImage(file){
        if(this.createdTrailPoint.images.includes(file.xhr.response)){
            this.createdTrailPoint.images.splice(this.createdTrailPoint.images.indexOf(file.xhr.response),1);
            await ImagesService.deleteImage(file.xhr.response);
        }
    },
    async deleteTrailPointConfirm(){
        await this.deleteTrailPoint(this.deleteID);
        this.successMessage = "Your point deleted successfully!";
        this.$refs.PopupSuccess.open();
    },
    deletePointShow(id){
        this.deleteID = id;
        this.$refs.PopupDelete.open();
    },

    createPointShow() {
        if(this.$refs.createDropzone){
            this.$refs.createDropzone.removeAllFiles();
        }
        if(this.$refs.createDropzoneDetails){
            this.$refs.createDropzoneDetails.removeAllFiles();
        }
      this.createdTrailPoint = JSON.parse(
        JSON.stringify(this.defaultTrailPoint)
      );
      this.dialogCreatePoint = true;
    },
    closeDialogCreatePoint() {
      this.dialogCreatePoint = false;
      this.createdTrailPoint = JSON.parse(
        JSON.stringify(this.defaultTrailPoint)
      );
    },

    async createNewTrailPoint() {
        this.createdTrailPoint.routes_id = this.trail.id;
        await this.createTrailPoint(this.createdTrailPoint);
        this.successMessage = "You trail point created successfully!";
        this.dialogCreatePoint = false;
        this.$refs.PopupSuccess.open();
        this.createdTrailPoint = JSON.parse(
            JSON.stringify(this.defaultTrailPoint)
        );
    },
    changeLang(lang) {
      this.$store.commit("landing/setCurrentLang", lang);
    },
    openStyles() {
      this.content.styleSettings = false;
    },
    async changeStatus(point) {
      if (point.active) {
        point.active = 0;
      } else {
        point.active = 1;
      }
      await this.editTrailPoint(point);
    },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    handleSuccessTrailPointMainImage(file) {
      this.createdTrailPoint.main_img = file.xhr.response;
    },
    customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append(
        "lending_settings_id",
        this.$store.state.landing.settings_id
      );
      formData.append("image", _file);
    },
    async handleRemoveTrailPointMainImage(file) {
      if (file.name === this.createdTrailPoint.main_img) {
        await ImagesService.deleteImage(file.name);
        this.createdTrailPoint.main_img = "";
      } else if (file?.xhr?.response === this.createdTrailPoint.main_img) {
        await ImagesService.deleteImage(file.name);
      }
    },
  },
  computed: {
    ...mapState(["landing"]),
    ...mapState("trails", ["trail"]),
    activeLang: {
      set: function (val) {},
      get: function () {
        return this.landing.advanced.selected_langs.indexOf(
          this.landing.current_lang
        );
      },
    },
  },
  components: {
      TypeTrailPointsStyles,
    vueDropzone,
    PopupSuccess,
    PopupDelete
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.showMce = true;
      }, 3000);
    });
  },
  beforeDestroy() {
    this.showMce = false;
  },
};
</script>

<style lang="scss" scoped></style>
